<template>
  <div>
    <CompanyProductReportChart class="mb-5" />
    <CompanyProductReportList />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
  name: 'companyProductReport',
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Müşteri Yönetim Paneli', route: '/musteri-yonetim-paneli' }, 
      { title: 'Ürün Listesi', route :`/musteri-yonetim-paneli/${this.$route.params.companyId}/urunler` },
      { title: 'Ürün Raporu' },
    ]);
  },
  components: {
    CompanyProductReportList: () => import('@/components/companyProduct/Report/List'),
    CompanyProductReportChart: () => import('@/components/companyProduct/Report/Chart'),
  },
};
</script>
